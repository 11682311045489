// Sales data widgets
.nk-sale-data{
	flex-shrink: 0;
	&-group{
		display: flex;
		flex-wrap: wrap;
	}
	.amount{
		display: block;
		font-size: 1.75rem;
		color: $base-color;
		.change{
			font-size: .875rem;
		}
		&.sm{
			font-size: 1.125rem;
			color: $base-light;
		}
	}
	.sub-title{
		color: $base-light;
		font-size: $fx-sz-12;
		.change{
			margin-right: 0.5rem;
		}
	}
}

.nk-sales-ck{
	height: 80px;
	flex-grow: 1;
	&.large{
		height: 200px;
	}
}

@include media-breakpoint-between(lg,xl){
	.nk-sales-ck{
		&.sales-revenue{
			height: 164px;
		}
	}
	
}

//Activity Widgets
.nk-activity{
	&-item{
		display: flex;
		align-items: center;
		padding: 1.25rem;
		&:not(:last-child){
			border-bottom: 1px solid $border-light;
		}
	}
	&-data{
		margin-left: 1rem;
		.time{
			display: block;
			font-size: $fx-sz-12;
			color: $base-light;
			line-height: 1.3;
		}
	}
}
@include media-breakpoint-up(sm){
	.nk-activity{
		&-item{
			display: flex;
			padding: 1rem 1.5rem;
		}
	}
}

//Activity Widgets
.nk-support{
	&-item{
		display: flex;
		padding: 1.25rem;
		&:not(:last-child){
			border-bottom: 1px solid $border-light;
		}
	}
	&-content{
		flex-grow: 1;
		margin-left: 1rem;
		.title{
			font-size: 0.875rem;
			font-weight: $fw-bold;
			color:$base-color;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		p{
			margin-bottom: 0;
			margin-top: 0.25rem;
		}
		.time{
			display: block;
			font-size: $fx-sz-12;
			color: $base-light;
		}
	}
}
@include media-breakpoint-up(sm){
	.nk-support{
		&-item{
			display: flex;
			padding: 1.25rem 1.5rem;
		}
	}
}