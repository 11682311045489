@each $size, $length in $spacers {
    .g-#{$size} {
        margin: - $length/2 !important;
        > li,> div{
            padding: $length/2 !important;
        }
    }
    .gx-#{$size} {
        margin-left: - $length/2 !important;
        margin-right: - $length/2 !important;
        > li,> div{
            padding-left: $length/2 !important;
            padding-right: $length/2 !important;
        }
    }
    .gy-#{$size} {
        margin-top: - $length/2 !important;
        margin-bottom: - $length/2 !important;
        > li,> div{
            padding-top: $length/2 !important;
            padding-bottom: $length/2 !important;
        }
    }
}

.gap {
    width: 100%;
    display: block;
    height: $gutter-width;
}
@each $size, $length in $spacers {
    .gap-#{$size} {
        height: $length;
    }
}

$gaps: sm 0.75rem, md 1.25rem, lg 2rem, xl 2.5rem;
@each $gap, $size in $gaps {
    .gap-#{$gap} {
        height: $size;
    }
}

$px-gaps: 10, 20, 30, 40, 50, 60, 70, 80, 90, 100;
@each $gap in $px-gaps {
    .gap-#{$gap}px {
        height: #{$gap}px;
    }
}
