.jqvmap-label
{
	position: absolute;
	display: none;
	border-radius: $border-radius;
	background: rgba($dark,.9);
	color: $white;
	padding: .25rem .75rem;
	  pointer-events:none;
	  font-size: $fx-sz-12;
}
.jqvmap-pin {
  pointer-events:none;
}
.jqvmap-zoomin, .jqvmap-zoomout
{
	position: absolute;
	left: 10px;
	border-radius: $border-radius-sm;
	background: rgba($dark,.9);
	padding: .125rem;
	color: $white;
	width: 1rem;
	height: 1rem;
	cursor: pointer;
	line-height: .75rem;
	text-align: center;
}
.jqvmap-zoomin
{
	top: 10px;
}
.jqvmap-zoomout
{
	top: 30px;
}
.jqvmap-region
{
  cursor: pointer;
}
.jqvmap-ajax_response
{
  width: 100%;
  height: 500px;
}
